<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormInput
          id="email"
          v-model="email"
          data-cy="input-email"
          name="Email"
          label="Email"
          placeholder="Enter your email address"
          type="text"
          rules="email"
          vid="email"
          required
        />

        <div class="d-flex justify-content-center mt-3">
          <b-alert
            data-cy="alert-error"
            :show="!!errorMessage"
            variant="danger"
          >
            {{ errorMessage }}
          </b-alert>

          <BaseButtonLoader
            button-class="mx-2 pulse"
            type="button"
            data-cy="button-back"
            @click="goBackToLogin()"
          >
            Back
          </BaseButtonLoader>

          <BaseButtonLoader
            button-class="mx-2 custom-button--white-hover-grad pulse"
            type="submit"
            data-cy="button-submit"
            :disabled="invalid"
            :loading="isLoading"
          >
            Submit
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPassword',

  props: {
    errorMessage: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      email: '',
    }
  },

  methods: {
    ...mapActions('auth', ['forgotPassword']),
    async onSubmit() {
      this.$emit('submit')
      try {
        this.$emit('submit')
        const response = await this.forgotPassword(this.email)
        const payload = {email: this.email, resendPassword: response.data?.resendPassword}
        this.$emit('success', payload)
      } catch(error) {
        this.$emit('error', error.response?.data?.title)
      }
    },
    goBackToLogin() {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style></style>
