<template>
  <b-container class="mx-sm-auto login__wrapper">
    <div class="w-50 mx-auto">
      <RingbaXLogoDark />
    </div>

    <h5
      v-if="showSuccess"
      data-cy="header"
      class="mb-4"
    >
      Your password has been changed successfully.<BaseLink to="/login">
        Click here
      </BaseLink>to login using your new password
    </h5>

    <h5 v-if="showResendPassword">
      <div>
        Please check your email for detailed instructions on setting up your new password.
      </div>
      <br>
      <div>
        <small class="text-muted">
          <p>
            If you haven't received the email, please be sure to check your spam folder. 
            Need help? Contact our support team at x@ringba.com or (800) 525-0000.
          </p>
        </small>
      </div>
    </h5>

    <h5 v-else-if="currentPasswordResetStage === 'ENTER_EMAIL'">
      Please enter your registered email address
    </h5>

    <h5 v-else-if="currentPasswordResetStage === 'SET_NEW_PASSWORD'">
      Enter a new password along with the code received on your email.

      <div>
        <small class="text-muted">
          Check your spam folder in case you haven't received a code yet
        </small>
      </div>
    </h5>

    <transition
      name="component-fade"
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="stepComponent"
          v-if="!showResendPassword"
          :is-loading="isLoading"
          :email="email"
          @submit="onSubmit"
          @success="onSuccess"
          @error="onError"
          @back="onBack"
        />
      </keep-alive>
    </transition>
  </b-container>
</template>

<script>
import RingbaXLogoDark from '@/assets/img/ringbax-logo-dark-theme.svg'
import ForgotPassword from '@/components/change-password/ForgotPassword.vue'
import SetNewPassword from '@/components/change-password/SetNewPassword.vue'
import { PASSWORD_RESET_STAGES } from '@/store/modules/auth.store'
import Vue from 'vue'

const stageComponents = {
  [PASSWORD_RESET_STAGES.ENTER_EMAIL]: ForgotPassword,
  [PASSWORD_RESET_STAGES.SET_NEW_PASSWORD]: SetNewPassword
}

export default {
  name: 'PasswordReset',

  title: 'Reset Password',

  components: {
    RingbaXLogoDark,
    ForgotPassword,
    SetNewPassword
  },

  data() {
    return {
      currentPasswordResetStage: PASSWORD_RESET_STAGES.ENTER_EMAIL,
      showSuccess: false,
      isLoading: false,
      email: '',
      showResendPassword: false,
    }
  },

  computed: {
    stepComponent() {
      return stageComponents[this.currentPasswordResetStage]
    }
  },

  methods: {
    onSubmit() {
      this.isLoading = true
    },
    onError(error) {
      this.isLoading = false
      Vue.notify({
        group: 'default',
        title: error,
        type: 'error',
        duration: 300000
      })
    },
    onBack() {
      if (
        this.currentPasswordResetStage ===
        PASSWORD_RESET_STAGES.SET_NEW_PASSWORD
      ) {
        this.currentPasswordResetStage = PASSWORD_RESET_STAGES.ENTER_EMAIL
      }
    },
    onSuccess(payload) {      
      this.isLoading = false

      if (payload?.resendPassword){
        this.showResendPassword = true
        this.showSuccess = false
        this.email = ''
        return
      }

      switch (this.currentPasswordResetStage) {
      case PASSWORD_RESET_STAGES.ENTER_EMAIL:
        this.currentPasswordResetStage = PASSWORD_RESET_STAGES.SET_NEW_PASSWORD
        this.email = payload.email
        break

      case PASSWORD_RESET_STAGES.SET_NEW_PASSWORD:
        this.currentPasswordResetStage = ''
        this.showSuccess = true
        break

      default:
        this.currentPasswordResetStage = ''
        this.showSuccess = true
        break
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.login__wrapper {
  min-height: 400px;
}

@include media-breakpoint-up(sm) {
  .login__wrapper {
    width: 467px;
  }
}
</style>
