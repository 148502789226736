<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormInput
          id="email"
          v-model="email"
          readonly
          name="Email"
          label="Email"
          type="text"
          vid="email"
          required
          data-cy="input-email"
        />

        <BaseFormInput
          id="new-password"
          v-model="newPassword"
          data-cy="input-new-password"
          name="New Password"
          label="New Password"
          placeholder="Enter your new password"
          type="password"
          vid="new-password"
          :rules="{ regex: PASSWORD_REGEX }"
          required
        />

        <BaseFormInput
          id="confirm-password"
          v-model="confirmPassword"
          data-cy="input-confirm-password"
          name="Password Confirmation"
          label="Confirm Password"
          placeholder="Re-enter password"
          type="password"
          rules="confirmed:new-password"
          required
        />

        <BaseFormInput
          id="confirmationCode"
          v-model="confirmationCode"
          data-cy="input-confirmation-code"
          name="Confirmation Code"
          label="Confirmation Code"
          placeholder="Enter confirmation code"
          type="text"
          vid="confirmation-code"
          required
        />

        <div class="d-flex justify-content-center mt-3">
          <b-alert
            data-cy="alert-error"
            :show="!!errorMessage"
            variant="danger"
          >
            {{ errorMessage }}
          </b-alert>

          <BaseButtonLoader
            button-class="mx-2 pulse"
            type="button"
            data-cy="button-back"
            @click="goBack()"
          >
            Back
          </BaseButtonLoader>

          <BaseButtonLoader
            button-class="mx-2 custom-button--white-hover-grad pulse"
            type="submit"
            data-cy="button-submit"
            :disabled="invalid"
            :loading="isLoading"
          >
            Submit
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import { PASSWORD_REGEX } from '@/store/modules/auth.store'

import { mapActions } from 'vuex'

export default {
  name: 'SetNewPassword',

  props: {
    errorMessage: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      confirmationCode: '',
      PASSWORD_REGEX: Object.freeze(PASSWORD_REGEX)
    }
  },

  methods: {
    ...mapActions('auth', ['setNewPassword']),
    async onSubmit() {
      this.$emit('submit')
      try {
        this.$emit('submit')
        await this.setNewPassword({
          email: this.email,
          newPassword: this.newPassword,
          confirmationCode: this.confirmationCode
        })
        this.$emit('success')
      } catch (error) {
        console.log(error)
        this.$emit('error', error.response?.data?.title)
      }
    },
    goBack() {
      this.$emit('back')
    }
  }
}
</script>

<style></style>
